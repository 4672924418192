@import '../../../../../theme/main';

.pwdErrorFieldContainer {
  position: relative;

  .root {
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.06);
    background-color: #f1f4f8;
    border-radius: 5px;
    background-color: $white;
    padding-right: 4px;
  }

  .input {
    color: #4e55a2;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    background: $white;

    &.bigSize {
      height: 30px;
    }
  }

  .errorOutline {
    border-color: $color-error;
    background: $input-has-error-bkg;

    input {
      background-color: $input-has-error-bkg;
    }
  }

  .errorText {
    margin-left: 3px;
    color: $color-error;
    font-size: 12px !important;
    line-height: 12px;
  }
}
