@import '../../../theme/main';

.itemActions {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
  height: 60px;
  background-color: $white;

  &.rightPadding {
    padding-right: 32px;
  }
  .icon {
    margin: 0px 5px;
    padding: 9px 8px;

    &.garbageIcon {
      padding: 10px;
    }
    &:hover {
      background-color: $prospection-icon-primary-hovered !important;
    }

    &.redIcon {
      &:hover {
        background-color: $prospection-icon-danger-hovered !important;
      }
    }
  }

  .options {
    display: flex;

    &.displayNone {
      display: none;
    }

    .optionsIconContainer {
      display: none;
    }

    &:hover {
      .optionsIconContainer {
        display: flex;

        .add,
        .edit {
          background: $color-error;

          &:hover {
            background-color: #980808;
          }
        }
      }
    }
  }

  .dropDownIcon {
    padding: 0;

    &.close {
      svg {
        transform: rotate(180deg);
      }
    }
  }
}
