@import '../../../../theme/main';

$borderRadiusCard: 5px;
$borderColorCard: #d8e3f2;

.subscriptionCard {
  @include flex-column-center-center;
  width: 100%;
  max-width: 615px;
  color: $light-purple;
  background: $white;
  border: 1px solid $borderColorCard;
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: $borderRadiusCard;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 24px;
    color: $light-purple;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  ul {
    padding-left: 20px;
  }
  &Body {
    .infos {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      gap: 10px;
    }
    .sec {
      @include flex-column-center-center;
      padding: 10px;
      border: 1px solid $borderColorCard;
      border-radius: $borderRadiusCard;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);

      p {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        margin: 0;
      }
      strong {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
      }
      .readMore {
        display: flex;
        justify-content: center;
        letter-spacing: 2px;
        padding: 0 2px;
        border: 1px solid $color-primary;
        border-radius: 7px;
        margin-left: 5px;
        width: 30px;
        height: 20px;
        cursor: pointer;
        font-size: 16px;
      }
      .flexDiv {
        display: flex;
      }
      .cityName {
        span {
          text-transform: lowercase;
        }
        text-transform: capitalize;
      }
    }
  }
}
