@import '../../../theme/main';

.multiPlotSelectSynthesis {
  position: absolute;
  right: 170px;
  top: 35px;
  width: 450px;
  background-color: $white;
  border-radius: 10px;
  padding: 0px 10px 10px 10px;

  > p {
    color: $color-primary;
    text-align: center;
  }

  @media screen and (max-width: 1550px) {
    width: 365px;
    right: 115px;
  }
  @media screen and (max-width: 1300px) {
    width: 330px;
    right: 115px;
    text-align: center;
  }
  @media screen and (max-width: 1170px) {
    width: 250px;
    right: 90px;
  }
  @media screen and (max-width: 950px) {
    top: 95px;
  }

  .content {
    @include flex-center-center;
    font-size: 13px;

    @media screen and (max-width: 1170px) {
      @include flex-column-center-center;
    }

    div {
      @include flex-column-center-center;
      .badge {
        display: inline-flex;
        margin: 0;
        margin-bottom: 5px;
        padding: 10px;
        border-radius: 8px;
        color: $white;
        background: $color-primary;
      }
    }
    ul {
      width: 55%;
      list-style: none;
      margin: 0;
      margin-right: 10px;
      padding: 0;
      overflow-y: scroll;
      max-height: 120px;
      @media screen and (max-width: 1300px) {
        width: auto;
      }
      @media screen and (max-width: 1170px) {
        margin: 0;
        margin-bottom: 10px;
        max-height: 85px;
      }

      li {
        @include flex-center-center;
        p {
          color: $white;
          background: $color-primary;
          padding: 10px;
          margin: 0;
          width: 100%;
        }
        .close {
          margin-left: 5px;
          cursor: pointer;
          font-size: 18px;
          color: #d9d9d9;
        }
        .number {
          display: flex;
          justify-content: center;
          color: $white;
          background: $color-primary;
          margin-right: 5px;
          padding: 5px;
          border-radius: 50%;
          font-weight: 700;
          font-size: 12px;
          width: 20px;
          height: 20px;
        }
      }
      li:first-child {
        p {
          border-radius: 8px 8px 0 0;
        }
      }
      li:last-child {
        p {
          border-radius: 0 0 8px 8px;
        }
      }
      .oneItem {
        border-radius: 8px !important;
      }
    }
  }
}

.multiPlotSelectSynthesisPdf {
  width: 450px;
  background-color: $white;
  border-radius: 0 0 10px 10px;
  padding: 0px 10px 10px 10px;

  > p {
    color: $color-primary;
    text-align: center;
  }
  .warning {
    color: $color-error;
  }
  .content {
    @include flex-center-center;
    div {
      @include flex-column-center-center;
      .badge {
        display: inline-flex;
        margin: 0;
        margin-bottom: 5px;
        padding: 10px;
        border-radius: 8px;
        color: $white;
        background: $color-primary;
      }
    }
    ul {
      width: 60%;
      list-style: none;
      margin: 0;
      margin-right: 10px;
      padding: 0;

      li {
        @include flex-center-center;
        p {
          color: $white;
          background: $color-primary;
          padding: 10px;
          margin: 0;
          width: 100%;
        }
        .close {
          margin-left: 5px;
          cursor: pointer;
          font-size: 18px;
          color: #d9d9d9;
        }
        .number {
          display: flex;
          justify-content: center;
          color: $white;
          background: $color-primary;
          margin-right: 5px;
          padding: 5px;
          border-radius: 50%;
          font-weight: 700;
          font-size: 12px;
          width: 20px;
          height: 20px;
        }
      }
      li:first-child {
        p {
          border-radius: 8px 8px 0 0;
        }
      }
      li:last-child {
        p {
          border-radius: 0 0 8px 8px;
        }
      }
      .oneItem {
        border-radius: 8px !important;
      }
    }
  }
}

.arrowClose {
  position: absolute;
  left: 179px;
}
