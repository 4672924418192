@import '../../../../theme/main';

.mapPluginHelperModal {
  position: fixed;
  top: 140px;
  right: 105px;
  z-index: 1;
  color: $color-primary;
  background-color: $white;
  width: 300px;
  height: auto;
  margin: 0px;
  padding: 20px;
  border-radius: 10px;

  .xIconContainer {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 15px;
    cursor: pointer;

    img {
      width: 100%;
    }
  }

  .para {
    font-style: normal;
    color: $color-primary;

    ul {
      margin: 0;
      padding: 0;
      margin-left: 15px;

      svg,
      img {
        margin: 0 5px;
      }

      img {
        height: 15px;
        border-radius: 5px;
        padding: 3px;
        margin: 0 5px;
        margin-bottom: -2px;
      }

      .iconBase {
        background-color: $toolbar_hover_bg;
      }
      .cancelIcon {
        background-color: $color-error;
      }

      li {
        margin: 4px 0;
        line-height: 18px;
        vertical-align: middle;
      }
    }
  }
}
