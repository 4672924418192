@import '../../../../theme/main';

.passwordTab {
  @include flex-column-start-center;

  h1 {
    font-size: 35px;
    color: $light-purple;
    margin: 20px 0 80px 0;
    text-align: center;
  }

  .formContainer {
    min-width: 200px;
    max-width: 400px;
    width: 100%;
  }
}
