@import '../../../theme/main';

.dragWindow {
  position: absolute;
  z-index: 1000;
  border-radius: 5px;
  border: 1px solid $gray;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
  background-color: whitesmoke;
  overflow: hidden;
  cursor: default;

  .titleContainer {
    @include flex-center-center;
    background-color: $color-primary;
    color: $white;
    cursor: move;
    h4 {
      flex: 1;
      margin: 0;
      padding: 10px 0;
      text-align: center;
    }
    .closeBtn {
      padding: 6px;
    }
  }

  .content {
    padding: 15px;
  }
}
