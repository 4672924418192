@import '../../../../theme/main';

.loginForm {
  @include flex-column-center-center;
  width: 100%;

  > div {
    width: 100%;
    &:first-child {
      margin-bottom: 10px;
    }
    input {
      padding: 10px 5px;
    }
  }

  .rememberMe {
    align-self: flex-start;
    color: $white;

    &.colorBlue {
      color: $color-primary;
    }
  }

  .submitButton {
    margin-top: 30px;
    width: 100%;
    @include button-base-blue;
    height: 50px;
    font-size: 1.1em;

    &.lightBlue {
      background-color: #bac6f2;
      color: $color-primary;
    }
  }

  .error {
    border-radius: 3px;
    margin: 10px 0 0 0;
    color: $color-error;
    padding: 10px 5px;
    background-color: $input-has-error-bkg;

    p {
      margin: 0;
    }
  }
}
