@import '../../../../theme/main';

.legendContainer {
  display: flex;
  flex-direction: column;

  .legendRow {
    display: flex;
    align-items: center;

    label {
      font-size: 0.85rem;
    }
  }
}
