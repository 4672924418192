@import '../../../../theme/main';

.advancedPlotSearchModal {
  .title {
    text-align: center;
    margin: 0;
    margin-bottom: 30px;
  }

  .loadingContent {
  }

  .errorContent {
    p {
      margin: 0;
      line-height: 22px;
    }
    .supportEmail {
      text-align: center;
      margin-top: 20px;
    }
  }

  .fulfilledContent {
    p {
      font-size: 1em;
    }
  }
}
