@import '../../../theme/main';

.launcherTooltip {
  text-align: center;
  margin: 0;
  &.clickable {
    cursor: pointer;
  }
}

.launcherBtn {
  width: 160px;
  &.marginBottom {
    margin-bottom: 10px;
    align-self: flex-end;
  }
  &.withRightMargin {
    margin-right: 10px;
  }
  &.flexEnd {
    align-self: flex-end;
  }

  .buttonContent {
    @include flex-center-center;

    p {
      margin-right: 10px;
    }
  }

  button {
    &.mailshotBtn {
      @include flex-center-center;

      .btnContainer {
        margin-top: 20px;
        width: 100%;
        @include flex-between-center;

        button {
          width: 100px;
          height: 35px !important;

          &.btnValid {
            @include button-base-green;
            margin-right: 5px;
            flex: 0.5;

            &:disabled {
              cursor: default;
            }
          }

          &.btnCancel {
            @include button-base-red;
            flex: 0.5;
          }
        }
      }
    }
  }
}

.mailShotDragWindow {
  @include flex-column-center-center;

  .selectContent {
    width: 300px;
    .body {
      .help {
        font-style: italic;
        margin-top: 0;
      }

      .selectPlotCount {
        font-weight: 600;
      }

      p {
        text-align: center;
        color: $color-primary;
      }
    }

    .btnContainer {
      button {
        width: 100px;
      }
    }
  }
}

.checkboxContainer {
  width: 32px;
  &.dashboardFolder {
    padding: 9px 0;
    background-color: #f1f0f0;

    &.folderBg {
      background-color: #dfdede;
    }
  }

  &.rightPanelPlotContact {
    @include flex-column-center-center;
  }
}
