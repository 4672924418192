@import '../../../../../theme/main';

.cesiumStatusbar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background-color: $white;
  padding: 0 10px;

  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 1fr;

  &.hasError {
    grid-template-columns: 40% 20% 40%;
  }

  p {
    margin: 0;
    padding: 0;
    align-self: center;

    span {
      font-weight: 700;
    }

    &.error {
      color: $color-error;
      font-weight: 700;
      text-align: center;
    }

    &.svAddress {
      justify-self: flex-end;
    }
  }
}
