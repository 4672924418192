@import '../../../../theme/main';

.noDatasError {
  @include flex-column-center-center;
  height: 100%;
  padding: 20px 0;

  .crossIcon {
    position: relative;
    width: 50px;
    height: 50px;
    background-color: $color-error;
    border-radius: 50%;
    color: white;
  }

  p {
    margin: 0;
    text-align: center;
    padding-top: 20px;
    color: $color-primary;
    font-size: 1.3em;
    font-weight: 600;
  }
}
