.tab-urbanease-academy {
  .tab-inner {
    display: block;
    width: 100%;
    h1 {
      font-style: normal;
      font-weight: 800;
      font-size: 35px;
      line-height: 42px;
      color: #353b7d;
      margin-top: 0;
    }
  }
  .tab-4-body {
    .slider {
      overflow: hidden;
      width: 100%;
      .main-slides {
        outline: none;
        box-shadow: none !important;
        &:focus {
          outline: none !important;
          box-shadow: none !important;
          border: 0px !important;
        }
        .col-1 {
          margin: 5px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          h4 {
            font-style: normal;
            font-weight: 800;
            font-size: 22px;
            line-height: 29px;
            color: #353b7d;
            margin: 0px 0px 4px 30px;
            min-height: 60px;
          }
          .video {
            width: 100%;
            height: 250px;
            background: #f5f5f5;
            border-radius: 30px;
            box-shadow: none;
            iframe {
              border-radius: 30px;
            }
            .react-player__preview {
              border-radius: 30px;
            }
          }
        }
      }
      .slick-arrow {
        display: none !important;
      }

      .slick-list {
        .slick-track {
          display: flex;
        }
      }
      &-actions {
        display: flex;
        justify-content: center;
        align-content: center;
        margin: 10px;
        svg {
          background: #4e55a2;
          width: 40px;
          height: 40px;
          color: #ffffff;
          border-radius: 50%;
          padding: 6px;
          margin: 10px;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
    h2 {
      font-style: normal;
      font-weight: 800;
      color: #353b7d;
      margin: 35px 0px 25px 0px;
    }
    .accordian {
      font-style: normal;
      color: #353b7d;
      box-shadow: 0px;
      p {
        line-height: 20px;
      }
      a {
        color: #353b7d;
      }
      .MuiTypography-body1 {
        font-style: normal;
        font-size: 16px;
        font-weight: 600;
        color: #353b7d;
      }
      .MuiSvgIcon-root {
        color: #353b7d;
      }
      .MuiAccordionSummary-root {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }
  }
}
