@import '../../../../../theme/main';

.buildingIcon {
  background-color: transparent;
  .buildingMarker {
    @include flex-center-center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid cyan;
    color: cyan;
  }
}

.icon {
  width: 140px !important;
  top: -97px !important;
  left: -64px;

  .markerDivIcon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .triangle {
      height: 0;
      width: 0;
      border-right: 10px solid transparent;
      border-top: 10px solid $white;
      border-left: 10px solid transparent;
      margin-bottom: 10px;
      margin-top: -2px;
    }

    .content {
      width: 100%;
      padding: 5px 0;
      margin: 0;
      border-radius: 5px;
      background-color: $white;
      text-align: center;
      color: $color-primary;
      font-family: $primary-font;
      border: 2px solid $color-primary;
    }
  }
}
