@import '../../../../../theme/main';

.selectFolderItemCustom {
  @include flex-start-center;

  &.subfolder {
    margin-left: 20px;
  }

  img {
    width: 25px;
  }
  p {
    margin: 0;
    padding-left: 5px;
  }
}
