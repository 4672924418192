@import '../../../theme/main';

.deleteConfirmation {
  @include flex-column-center-center;

  p {
    text-align: center;
    span {
      font-style: italic;
      font-weight: 700;
    }
  }
  .input {
    width: 75%;
    margin-bottom: 20px;
    input {
      color: $modal-color-warning;
      height: 30px;
      font-size: 1.1em;
      text-align: center;
    }
    fieldset {
      border-color: $modal-color-warning;

      &:hover {
        border-color: $modal-color-warning;
      }
    }
  }
  .button {
    @include button-base-blue;
    width: 50%;
    margin: auto;
    background-color: $modal-color-warning;
    font-weight: 700;
    font-size: 1.1em;
    border-color: $modal-color-warning;
    cursor: pointer;

    &.disabled {
      background-color: $modal-color-shadow;
      border-color: $modal-color-shadow;
      cursor: not-allowed;
    }
  }
}
