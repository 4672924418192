@import '../../theme/main';

.externalConnection {
  display: flex;
  justify-content: center;
  background-image: url('../../assets/images/background_plu.webp');
  height: 100vh;
  overflow: scroll;

  &Container {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    border-radius: 25px;
    width: 600px;
    height: fit-content;
    padding: 20px;
    margin: 65px 0;
  }

  h1 {
    text-align: center;
  }

  .logoUrbanease {
    width: 275px;
  }
}

.firmDisplay {
  margin-bottom: 20px;
  img {
    width: 300px;
    max-height: 200px;
    margin-top: 25px;
  }

  p {
    text-align: center;
    font-size: 2em;
    margin: 0;
    margin-top: 30px;

    span {
      font-weight: 700;
      font-style: italic;
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
