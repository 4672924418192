@import '../../../theme/main';

.environmentRow {
  margin-left: 0 !important;
}

.brownfieldPopupContainer {
  width: 301px;
  border: 1px solid $color-primary;

  .tabContent {
    @include flex-center-center;
    height: 30px;
    font-weight: 700;
    font-size: 1.2em;
    color: $white;
    background-color: $color-primary;
  }

  .brownfieldContent {
    padding: 10px;

    p {
      margin: 0 0 2px 0;
      font-weight: 700;

      span {
        font-weight: 400;
      }
    }

    ul {
      margin: 0;
      padding-left: 30px;
    }
  }
}
