@import '../../theme/main';

.maintenancePage {
  @include flex-center-center;
  position: relative;
  background-image: url('../../assets/images/background_plu.webp');
  height: 100vh;
  overflow: scroll;

  .content {
    @include flex-column-start-center;
    background-color: $white;
    border-radius: 25px;
    width: 600px;
    padding: 20px;

    .body {
      margin-top: 40px;
      p {
        text-align: center;
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}
