@import './../../../../../../../../../theme/main';

.constructCommentContainer {
  .fieldContainer {
    @include flex-start-center;
    margin-bottom: 10px;

    label {
      width: 240px;
    }
    > div {
      width: 150px;
    }
  }

  .constructComment {
    width: 100%;
    height: 160px !important;
    padding: 10px;
    background-color: white;
    border: 1px solid grey;
    border-radius: 4px;
  }

  .saveButton {
    @include button-base-blue;
    width: 300px;
    display: block;
    margin: 15px auto 0 auto;
  }
}
