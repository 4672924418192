@import '../../../../theme/main';

.loaderContent {
  height: 300px;

  h4 {
    text-align: center;
    margin-bottom: 40px;
  }
}
.contactModalContent {
  max-height: 600px;
  width: 100%;
  .patternField {
    width: 250px;
    input {
      height: 20px;
    }
  }

  .text-field {
    margin: 20px;
    width: 30%;
  }
  .tableContainer {
    width: 100%;
    height: 300px;
    margin-top: 20px;
    overflow-y: scroll;
    table {
      border-collapse: collapse;
      width: 100%;

      overflow: scroll;

      th {
        padding-bottom: 10px;
      }

      td,
      th {
        border-bottom: 2px solid $background-grey;

        &:nth-child(1) {
          // name
          width: 30%;
        }
        &:nth-child(2) {
          // phone
          width: 10%;
        }
        &:nth-child(3) {
          // mail
          width: 30%;
        }
      }

      .row {
        &:hover {
          background-color: $background-grey;
          cursor: pointer;
        }

        td {
          padding: 5px;
          font-size: 0.9em;

          a {
            font-weight: 500;
            text-decoration: underline;
            color: #0000ee; //default link color
          }
        }
      }
    }
  }
}
