@import '../../../../../../theme/main';

.tab {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px;
  width: 100%;
  height: 54px;
  border-left: 4px solid #4e55a2;
  background-color: #ededed;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
  font-size: 12px;

  .menu-text {
    line-height: 14px;
    text-align: center;
  }
  img {
    right: -14px;
    height: 20px;
    position: absolute;
  }

  &.selected {
    background-color: $color-primary;
    color: $white;
  }
}
