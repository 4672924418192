@import '../../../../../../../../../theme/main';

.managementContent {
  @include flex-center-center;
  align-items: flex-start;

  > div {
    flex-grow: 1;
  }

  .folderInput {
    margin-bottom: 5px;
  }

  .prioritySelect,
  .statusSelect,
  .responsableSelect {
    @include flex-center-center;
    margin-bottom: 5px;

    label {
      width: 60%;
      display: flex;
      justify-content: flex-end;
      padding-right: 10px;
    }
  }
}
.selectedValue {
  @include flex-start-center;

  .priorityIcon {
    margin-right: 5px;
  }
}
