@import '../../../../../../theme/main';

.dynamicErrorMessageContainer {
  position: absolute;
  background-color: $white;
  width: (100%);
  padding: 10px 5px;
  z-index: 1000;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 50%);

  .crossIcon {
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .tickSquareContainer {
    @include flex-center-center;
    width: 18px;
  }
  p {
    color: $color-primary;
    margin: 0;
  }

  ul {
    margin: 10px 0 0 0;

    list-style-type: none;
    padding-left: 20px;

    li {
      @include flex-start-center;
      padding: 2px 0;

      span {
        margin-left: 5px;
        color: $color-success;

        &.error {
          color: $color-error;
        }
      }
    }
  }
}
