@import '../../../../../theme/main';

.left-panel {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 10;
  width: 450px;
  max-height: 100%;
  overflow-y: auto;
  // overflow-x: hidden;

  transition: all 0.4s ease;
  border-radius: 10px;

  &.collapsed-panel {
    background-color: $white;
    align-items: center;
    height: 100%;
    width: 109px;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 20%);
  }

  &.external-pdf-errial {
    margin-top: 55px;
    overflow-y: initial;
    border-radius: 0 0 10px 10px;
    .left-panel-search-container {
      border-radius: 0 0 10px 10px;
    }
  }

  &-search-container {
    background: $white;
    border-radius: 10px;
  }

  &-header {
    display: flex;
    padding: 15px 20px;
    max-height: 70px;
    justify-content: center;
    border-bottom: 1px solid $gray-border;

    img {
      max-height: 70px;
      width: 180px;
    }
  }

  &-multi-plots {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    &-empty {
      p {
        color: $color-error;
      }
    }
    div {
      @include flex-center-center;
      padding: 0 20px 20px 20px;
      svg {
        width: 36px;
        height: 22px;
        margin-right: 5px;
      }
      p {
        margin: 0;
      }
    }
    button {
      @include flex-center-center;
      height: 35px;
      width: auto;
      text-align: center;
      color: $white;
      background: $color-primary;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
      border-radius: 2px;
      border: none;
      cursor: pointer;
      padding: 0 15px;
      margin-bottom: 20px;
    }
    .disable {
      opacity: 0.6;
      cursor: default;
    }
  }

  .collapse-icon-container {
    position: fixed;
    top: 95px;
    left: 450px;
    cursor: pointer;
    transition: all 0.4s ease;

    img {
      height: 30px;
    }

    &.collapsed-icon {
      left: 109px;
    }
  }
}
