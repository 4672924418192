@import '../../../../theme/main';

.subscriptionLimitModal {
  p {
    padding: 0 20px;
    line-height: 1.4rem;
  }

  .supportEmail {
    text-align: center;
  }
}
