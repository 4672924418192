@import '../../../theme/main';

.iconContainer {
  @include flex-center-center;

  cursor: pointer;
  padding: 8px;

  &.roundIcon {
    border-radius: 50%;
  }
  &.procedureDeleteIcon {
    border-radius: 50%;
    &:hover {
      background-color: $prospection-icon-primary-hovered !important;
    }
  }
  &.noPadding {
    padding: 0;
  }
  svg {
    width: 100%;

    &.isOpen {
      transform: rotate(180deg);
    }
  }
}
