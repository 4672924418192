@import '../../../../theme/main';

.checkContainer {
  .checkLabel {
    span {
      font-size: 14px;
    }
  }
}

.switchContainer {
  p {
    margin: 0;
  }
  .errorSwitch {
    margin-top: 10px;
    color: $color-error;
    font-style: italic;
  }

  > div {
    @include flex-center-center;
  }
}

.radioGroupContainer {
  margin: 10px 0;
  background-color: inherit;
  border-color: whitesmoke;
  border-radius: 5px;

  &.error {
    border-color: $input-has-error-border;
    background-color: $input-has-error-bkg;
    p {
      margin: 0;
      text-align: left;
      font-size: 0.75rem;
      font-style: italic;
      color: $color-error;
    }
  }

  .groupLabel {
    color: $color-primary;
  }
  .radioGroup {
    .radioLabel {
    }
  }
}

.selectContainer {
  display: flex;
  flex-direction: column;

  .selectMenuItem {
    line-height: 1 !important;
    font-size: inherit !important;
    padding: 4px 6px !important;
  }
}

.error {
  fieldset {
    border-color: $input-has-error-border !important;
  }
  input {
    background-color: $input-has-error-bkg;
  }
  p {
    margin: 0;
    text-align: left !important;
    font-size: 0.75rem;
    color: $color-error;
  }
}
