@import '../../../theme/main';

.badge {
  display: inline-block;
  padding: 3px 5px;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;

  @each $class, $color in $colorList {
    &-#{$class} {
      color: white;
      background-color: $color;
    }
  }
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='small']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='small']::before {
  content: 'Petit';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='normal']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='normal']::before {
  content: 'Normal';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='large']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='large']::before {
  content: 'Grand';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='huge']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='huge']::before {
  content: 'Énorme';
}
