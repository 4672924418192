@import '../../../../theme/main';

.tab-subscription {
  h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 42px;
    color: #353b7d;
    margin-top: 0;
  }

  .tab-body {
    @include flex-column-center-center;
  }
}
