@import '../../../../theme/main';

.choose-company-modal {
  display: flex;
  flex-direction: column;
  max-height: 400px;

  .company-list {
    max-height: 250px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    button {
      margin-bottom: 10px;
      padding: 5px;
      font-size: 15px;
      text-align: center;
      color: $color-primary;
      background-color: $white;
      border: 1px solid $color-primary;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        color: $white;
        background-color: $color-primary;
      }
    }
  }
}
