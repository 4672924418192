@import '../../../../theme/main';

.tab-data-owners {
  display: flex;
  flex-direction: column;
  .tab-inner {
    display: block;
    width: 100%;
    h1 {
      font-style: normal;
      font-weight: 800;
      font-size: 35px;
      line-height: 42px;
      color: #353b7d;
      margin-top: 0;
    }
  }
  .tab-body {
    display: flex;
    flex-direction: column;

    .search-engine {
      position: relative;
      .commune-engine-componants {
        height: 60px;
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        position: relative;
        .right-bloc {
          display: flex;
          position: absolute;
          width: 234px;
          right: 0;
          text-align: start;

          .sort-select {
            flex-grow: 1;
          }

          .sort-buttons {
            display: flex;
            flex-direction: column;
            width: 30px;
            padding: 0 0 0 10px;

            .icon {
              height: 20px;
              background-color: $light-gray-bg;
              color: $gray;
              width: 100%;
              cursor: pointer;

              &.selected {
                color: $black;
              }

              &.desc {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
      .search-mode {
        display: flex;
        margin-bottom: 10px;

        button {
          width: 150px;
          background-color: $white;
          color: $black;
          margin-left: 10px;

          &.selected {
            background-color: $color-primary;
            color: $white;
          }
        }
      }

      .search-by-name-button {
        margin-bottom: 10px;
      }

      .generic-search {
        display: flex;
        align-items: center;

        .MuiFormControl-root {
          width: 300px;
          margin: 5px 0;

          .MuiInputBase-input {
            height: 22px;
          }
        }

        .input-research {
          margin-right: 10px;
        }

        .research-btn {
          width: 100px;
        }
      }

      .autocomplete-city {
        width: 30%;
      }
    }

    .owner-infos {
      display: flex;
      margin: 5px 0;

      > div {
        padding: 5px;
        flex-grow: 1;
      }
    }
    .paper-table {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      .folder-add-block {
        display: flex;
        justify-content: flex-end;
        button {
          color: $white;
          width: 150px;
        }
        .Mui-disabled {
          background-color: $gray;
        }
      }

      .plot-studies-table {
        margin-top: 5px;
        th,
        td {
          text-align: center;
        }
        td {
          padding: 0;
        }
        .plot-studies-loader {
          padding: 30px;
        }
      }
    }
  }
}
