@import './mixins/minix_modals';
@import './mixins/mixin_flex';

// Media Queries Mixins
@mixin respond-at-max($media) {
  @media only screen and (max-width: $media) {
    @content;
  }
}
@mixin respond-at-min($media) {
  @media only screen and (min-width: $media) {
    @content;
  }
}
@mixin respond-at($media) {
  @media only screen and (width: $media) {
    @content;
  }
}

@mixin table-row-border-bottom {
  border-bottom: 1px solid $row-border-botom-color;
}
@mixin button-base {
  cursor: pointer;
  color: $white;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 2px;
}

@mixin button-base-blue {
  @include button-base;

  background-color: $color-primary;
  border: 1px solid $color-primary;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
}

@mixin button-base-red {
  @include button-base;

  background-color: $color-error;
  border: 1px solid $color-error;
  box-shadow: 0 2px 4px 0 rgb(210 0 0 / 50%);
}

@mixin button-base-green {
  @include button-base;

  background-color: $color-success;
  border: 1px solid $color-success;
  box-shadow: 0 2px 4px 0 rgb(0 210 0 / 50%);
}

@mixin rounded-map-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  width: 59px;
  border-radius: 10px;
  cursor: pointer;
  position: fixed;
}

@mixin green-submit-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #75cc8b;
  color: #ffffff;
  border: none;
  border-radius: 2px;
  width: 237px;
  height: 48px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

@mixin cancel-btn {
  @include green-submit-btn;
  background: $primary-button-cancel-bg;
}

@mixin flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-between-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@mixin parcel-info-accordion {
  p {
    font-weight: 600;
    margin: 10px 0;

    span {
      font-weight: 400;
    }
  }

  .form-btn {
    width: auto;
    margin-top: 30px;
    height: 30px;
    width: 330px;

    &:hover {
      color: #fff;
      background-color: $color-primary;
    }
  }
}

@mixin right-panel-modal-header {
  background-image: url('../assets/images/transparent_pin.png');
  background-size: 80px;
  background-repeat: no-repeat;
  background-position-y: -36px;
  color: $white;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: $color-primary;
  height: 180px;

  > div {
    width: 100%;
  }
}

@mixin environment-container {
  width: 100%;
  border: 1px dashed lightblue;
  min-height: 200px;
  background: $white;
  padding: 10px;
  position: relative;
}

@mixin toolbar_container {
  background-color: transparent;
  display: flex;
  margin-left: -5px;
  margin-right: -5px;
  justify-content: center;
  height: 53px;
  color: $color-primary;
}

@mixin form-error-component {
  border-color: $input-has-error-border;
  background-color: $input-has-error-bkg;

  fieldset {
    border-color: $input-has-error-border !important;
  }

  input {
    background-color: $input-has-error-bkg;
  }

  p {
    margin: 0;
    text-align: left;
    font-size: 0.75rem;
    font-style: italic;
    color: $color-error;
  }
}
