@import '../../../theme/main';

.accordionContainer {
  width: 140px;
  height: 100%;

  div,
  section,
  button {
    width: 100%;
  }

  @include flex-column-start-center;
  .accordionElement {
    margin-bottom: 2px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
    h3 {
      @include flex-center-center;
      border-radius: 2px;
      font-size: 1em;
      background-color: whitesmoke;
      margin: 0;
      font-weight: 400;
      height: 40px;
      cursor: pointer;
      color: $color-primary;

      .title {
        width: 90%;
        text-align: center;
      }
      &:hover {
        background-color: adjust-brightness(whitesmoke, 3);
      }
      &:active {
        background-color: adjust-brightness(whitesmoke, 5);
      }

      &.disabled {
        cursor: default;
        background-color: adjust-brightness(whitesmoke, -5);
        color: adjust-brightness($color-primary, -20);

        &:hover {
          font-weight: inherit;
        }
        &:active {
          background-color: inherit;
        }
      }

      .iconContainer {
        width: 20px;
        .expandIcon {
          padding: 0;
        }
      }

      &.selected {
        background-color: adjust-brightness(whitesmoke, 10);

        .expandIcon {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
    .content {
      padding: 3px;
    }
  }
}
