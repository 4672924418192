@import '../../../../theme/main';

.formContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .input {
    width: 70%;
    margin: 30px 0;
    input {
      height: 25px !important;
      font-size: 1em;
    }
  }
  button {
    @include button-base-blue;
    width: 50%;
    margin: 30px 0;
    font-size: 1em;
    font-weight: 700;
  }
}
