/*lato font-family*/

@font-face {
  font-family: "lato-regular";
  src: url("../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "lato-black";
  src: url("../assets/fonts/Lato/Lato-Black.ttf") format("truetype");
}

@font-face {
  font-family: "lato-bold";
  src: url("../assets/fonts/Lato/Lato-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "lato-light";
  src: url("../assets/fonts/Lato/Lato-Light.ttf") format("truetype");
}

@font-face {
  font-family: "lato-thin";
  src: url("../assets/fonts/Lato/Lato-Thin.ttf") format("truetype");
}

/*roboto font-family*/
@font-face {
  font-family: "roboto-regular";
  src: url("../assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "roboto-black";
  src: url("../assets/fonts/Roboto/Roboto-Black.ttf") format("truetype");
}

@font-face {
  font-family: "roboto-bold";
  src: url("../assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "lato-light";
  src: url("../assets/fonts/Roboto/Roboto-Light.ttf") format("truetype");
}
