.dvf-icon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: cyan;
}

.dvf-popup-content {
  display: flex;
  flex-direction: column;
  width: 200px;
}
.dvf-popup-header {
  text-align: center;
  font-weight: 700;
}

.dvf-popup-body {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  padding: 5px;
  max-height: 200px;
  overflow-y: scroll;
}

.dvf-popup-body-transaction {
  margin: 10px 0;

  p {
    margin: 0;
    font-weight: 600;

    span {
      font-weight: 400;
    }
  }
}
