@import '../../.././../theme/main';

.formButtonContainer {
  @include flex-center-center;
  margin-top: 20px;
  width: 100%;

  // div button container
  .button {
    height: 35px;
    max-width: 200px;
    flex: 1;

    &:first-child {
      margin-right: 10px;
    }
  }
}
