@import '../../../../theme/main';

.plotServitudesLegend {
  padding: 0 20px;
  color: $color-primary;

  .loadingError {
    a {
      font-weight: 400;
    }
  }
  .title {
    margin-bottom: 0 !important;
  }

  h2 ~ p {
    margin: 0;
    font-style: italic;
    color: black;
    font-size: 0.9em;
  }

  h3 {
    margin-bottom: 5px;
  }

  h3 ~ p {
    font-size: 14px;
    padding-left: 20px;
    color: $color-primary;
  }

  .section {
    padding-left: 20px;

    .groupRow {
      .groupHeader {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $light-gray-bg;
        border-radius: 5px;
        margin-top: 1px;
        // padding: 5px 5px 5px 0px;

        label {
          flex-grow: 1;
        }

        .expandIcon {
          cursor: pointer;

          &.isOpen {
            transform: rotate(180deg);
          }
        }
      }
      .groupRowChildContainer {
        padding-left: 20px;
        display: none;

        &.isVisible {
          display: block;
        }
      }
    }

    .sectionRow {
      input {
        cursor: pointer;
      }

      label {
        padding-left: 5px;
      }

      p {
        margin: 0;
      }
    }
  }
}

.pointMarker {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: yellow;
}
