@import '../../../../theme/main';

$borderRadiusCard: 5px;
$borderColorCard: #d8e3f2;

.switchDomTom {
  display: flex;
  flex-direction: column;
  border: 1px solid $borderColorCard;
  box-sizing: border-box;
  width: 100%;
  max-width: 615px;
  padding: 20px;
  border-radius: $borderRadiusCard;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

  h3 {
    color: $color-primary;
    text-align: left;
    margin: 6px 0 12px 0;
  }

  .switchDomTomContent {
    .label {
      font-size: 1.1em;
      margin: 0 20px;

      &.selected {
        color: $color-primary;
        font-weight: 700;
      }
    }
  }
}
