@import './../../../../../theme/main';

.matrice-content {
  > div {
    margin-bottom: 30px;
  }
  h3 {
    margin-left: 28%;
    color: red;
    font-weight: 900;
    font-size: 14px;
  }

  .generic-matrice-input {
    display: flex;
    align-items: center;
    margin: 10px;

    label {
      width: 45%;
      text-align: right;
    }

    .input-field {
      margin: 0 15px;
      max-width: 150px;

      input {
        line-height: normal;
      }

      &.calculated {
        input {
          background-color: $matrix-calculated-bg-color;
        }
      }
    }
  }
}
