@import '../../../../theme/main';

.btnPdf {
  @include flex-center-center;
  height: 40px;
  width: 185px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  background: #d2112f;
  border-radius: 2px;
  border: none;
  cursor: pointer;
  padding: 0 20px;
  margin: 5px auto;
  text-decoration: none;
  svg {
    fill: #ffffff;
    margin-left: 10px;
  }
  &:hover {
    color: #d2112f;
    border: 1px #d2112f solid;
    background: #ffffff;
    svg {
      fill: #d2112f;
    }
  }

  &.disabled {
    cursor: default;
    opacity: 0.6;
    &:hover {
      color: #ffffff;
      background: #d2112f;
      svg {
        fill: #ffffff;
      }
    }
  }

  &.hidden {
    visibility: hidden;
  }
}
