@import '../../../../../../theme/main';

.right-panel-modal-header {
  @include right-panel-modal-header;

  .is-folder {
    margin-top: 20px;
  }
  .header-central-bloc {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .parcelle-details {
      display: flex;

      .header-left {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .header-right-buttons {
        @include flex-column-center-center;
        margin-bottom: 10px;
      }
    }

    p {
      margin: 0;
      padding: 0;
      font-weight: 600;
    }

    .parcelle-details-sector {
      margin-top: 40px;
    }

    .title {
      color: $white;
      font-size: 24px;
      margin-bottom: 5px;
    }

    .title-indication {
      font-size: 10px;
      font-style: italic;
      color: $white;
    }

    .parcel-number {
      text-align: right;
    }

    &-plot {
      &.sector {
        margin-top: 20px;
      }
      .display-plot {
        color: white;
      }
    }
  }

  .panel-header-tabs {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;

    > div {
      margin: 0 3px;
    }

    .panel-header-tab {
      font-weight: 500;
      cursor: pointer;
      background-color: transparent;
      height: 34px;
      line-height: 32px;
      border-radius: 34px;
      padding: 0 20px;
      width: max-content;
      border: 1px solid rgba(255, 255, 255, 0.6);
      border-style: dashed;

      &.selected {
        background-color: $white;
        color: $color-primary;
      }
    }
  }
}
