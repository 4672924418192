@import '../../../theme/main';

.mailshotsAccountTabContent {
  .loaderContainer {
    height: 300px;

    .modelsLoaderMessage {
      text-align: center;
      margin: 40px 0;
      color: $color-primary;
      font-size: $header-menu-fonts-size;
    }
  }

  .mailshotsAccountTabNew {
    @include flex-around-center;
    padding: 10px;
    margin-bottom: 20px;
    color: $color-primary;
    background: $white;
    border-radius: 8px;
    border: 3px solid #d8e3f2;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);

    .new {
      color: $color-error;
    }

    span {
      font-weight: 600;
    }

    > *:not(:last-child) {
      margin-right: 15px;
    }

    &Link {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .quillContainer {
    @include flex-center-start;

    .quillContent {
      @include flex-column-center-center;
      flex: 1;
      justify-content: flex-start;

      .quillEditor {
        height: 450px;
        width: 100%;
      }
    }
    .tagButtonContainer {
      @include flex-column-center-center;
      width: 155px;
      height: 100%;
      margin-left: 5px;

      .logoUploadBtn {
        width: 100%;

        button {
          min-height: 35px;
          max-height: 45px;
          height: auto;
          padding: 5px 0;
        }

        p {
          margin: 0;
        }
      }

      .badgeBtnContainer {
        width: 100% !important;

        .button {
          height: auto;
          margin-bottom: 3px;

          button {
            min-height: 35px;
            max-height: 45px;
            height: auto;
            padding: 5px 0;
          }
        }
      }

      .logoHelp {
        font-style: italic;
        text-align: center;
        padding: 5px;
        color: $color-primary;
        background: $white;
        border-radius: 5px;
        border: 1px solid #d8e3f2;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        font-size: $small-font-size;
      }
    }
    .formBtnContainer {
      height: 40px;
      margin-top: 50px;
    }
  }
}

.badge {
  &-color {
    background-color: red;
  }
}
