@import '../../../../../../theme/main';

.measureToolbar {
  @include toolbar_container;
}

.measurePluginInterface {
  position: absolute;
  top: 60px;
  width: 300px;
  padding: 10px;
  background-color: $white;
  border-radius: 15px;
  font-size: 12px;

  h3 {
    text-align: center;
  }

  p {
    margin: 0;
    padding: 0;
  }

  hr {
    width: 90%;
    border-top: 1px solid #ddd;
  }

  ul {
    padding: 0 15px 0 0;
    li {
      cursor: pointer;
    }
  }

  img {
    width: 12px;
    height: 12px;
    margin-right: 5px;
    margin-bottom: 2px;
  }

  .startSection {
    .tasks {
      margin-top: 0;
      padding-left: 15px;
      padding-top: 0;
      border-top: 0;
      text-align: left;

      li {
        display: flex;
        align-items: center;
        width: 100%;
        height: 25px;
        line-height: 25px;
        &:hover {
          cursor: pointer;
        }
        p {
          color: #5e66cc;

          &.isRed {
            color: $color-error;
          }
        }
      }
    }
  }

  .areaStart {
    > p {
      margin: 10px 0;
      padding-left: 15px;
    }
    .areaStartOptions {
      display: flex;
      justify-content: flex-end;

      li {
        width: 100px;
        display: flex;
        justify-content: flex-start;
      }
    }
  }
}
