@import '../../../theme/_main.scss';

.header {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  .logo {
    height: 40px;
    margin-left: 50px;
    cursor: pointer;
  }

  .btnContainer {
    flex: 1;
    @include flex-end-center;

    button {
      @include flex-center-center;
      @include button-base-blue;
      height: 35px;
      padding: 0 15px;
      margin-right: 50px;

      svg {
        fill: $white;
        margin-left: 5px;
        height: 25px;
      }

      &.viewBtn {
        margin-right: 5px;
        background-color: $color-primary;
        border: 1px $color-primary solid;
        width: 145px;

        &:disabled {
          color: $color-primary;
          border: 1px $color-primary solid;
          border-bottom: 4px #d2112f solid;
          background: $white;
          cursor: default;
        }
      }
    }
  }
}
