.pagination {
  margin-top: 20px;
  display: flex;

  align-items: center;
  height: 50px;

  nav {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }

  .items-per-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;

    > div {
      width: 60px;
    }

    .MuiFormControl-root {
      border: none;
      background-color: transparent;
      box-shadow: none;
    }

    .MuiOutlinedInput-input {
      padding: 5px 0px 5px 10px;
    }
  }
}
