@import '../../theme/main';

.gm-iv-address-link a {
  position: relative;
  color: transparent; /* Hides the original text */
  text-decoration: none; /* Removes text decoration */
}
.gm-iv-address-link a::after {
  content: 'Voir sur google maps'; /* replacement text */
  position: absolute;
  left: 0;
  top: 0;
  color: #aecbfa; /* replacment text color */
}

.gm-iv-vertical-separator,
.gm-iv-marker-link {
  display: none; /*hide unused tool */
}

.gm-iv-address-description {
  padding-right: 5px; /* resize google address box */
}
