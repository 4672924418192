@import '../../../../../theme/main';

.checkboxCustom {
  width: 100%;
  @include flex-between-center;

  .checkbox {
    padding-top: 0px;
    padding-bottom: 2px;
  }
}
