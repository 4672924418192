@import '../../../../../theme/main';

.sr-only {
  display: none;
}

.loading-plot {
  cursor: wait;
}

.leaflet-interactive.leaflet-mouse-marker {
  cursor: crosshair;
}

.urbn-map {
  height: 100vh;
  width: 100%;
  position: relative;
  z-index: 0;

  .polygon-wrapper {
    stroke-width: 2;
  }

  .plot-layer {
    &.leaflet-interactive {
      stroke-width: 3;
      stroke: blue;
    }
    &.building-layer {
      &.leaflet-interactive {
        stroke-width: 2;
        stroke: red;
      }
    }
  }

  .marker-with-number {
    margin-left: -20px !important;
    margin-top: -50px !important;
    &.leaflet-interactive {
      stroke-width: 0;
    }
  }

  .layer-measure-resultline,
  .layer-measureboundary,
  .layer-measurevertex {
    &.leaflet-interactive {
      stroke-width: 3;
      stroke: #9b2d14;
    }
  }

  .layer-measure-resultarea {
    &.leaflet-interactive {
      stroke-width: 3;
      stroke: #9b2d14;
      fill: #9b2d14;
      fill-opacity: 0.3;
    }
  }

  .leaflet-control-container {
    .leaflet-right {
      &.leaflet-top {
        .leaflet-draw,
        .leaflet-measure,
        .polyline-measure {
          display: none;
        }
      }
    }

    &.leaflet-bottom {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100px;
      width: 160px;
      z-index: 501;

      .leaflet-control-zoom {
        display: flex;
      }

      .leaflet-control-attribution {
        display: none;
      }
    }
  }

  .leaflet-bar {
    border: none;
    a {
      width: 70px;
      height: 40px;
      line-height: 40px;
      border: none;
    }
  }

  .leaflet-control-zoom {
    .leaflet-control-zoom-in {
      color: white;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      background: $color-primary;
    }

    .leaflet-control-zoom-out {
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    }
  }

  .cross-folder {
    background-color: #353b7d;
    img {
      height: 30px;
    }
  }

  .MuiButton-root {
    &:first-child {
      border-radius: 4px 0px 0px 4px;
    }
    &:last-child {
      border-radius: 0px 4px 4px 0px;
    }
  }
  .MuiButton-contained {
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.65);
  }
  .MuiButton-containedPrimary {
    background-color: #353b7d;
    color: #ffffff;
  }
}
