@import '../../../../theme/main';

.loaderCircular {
  @include flex-column-center-center;
  flex-grow: 1;

  &.fullWidth {
    width: 100%;
  }
}
