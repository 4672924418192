@import '../../../../../theme/main';

.tick {
  &Green {
    fill: $color-success;
  }
  &Grey {
    fill: #e6e6e6;
  }
  &Red {
    fill: $color-error;
  }
}
