@import '../../../../../../../../../theme/main';

.display-button {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;

  > img {
    width: 60px;
  }

  > div {
    @include flex-center-center;
    font-weight: 700;
    color: $color-primary;
    font-size: 1.2em;
    width: 36px;
    height: 36px;
    opacity: 0.6;
    border-radius: 50%;
    box-shadow: 0 0 20px rgb(150, 150, 150, 0.7);
  }
  > p {
    margin-left: 20px;
    font-weight: 700;
  }
}
