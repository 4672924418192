@import '../../theme/main';

.main-wrapper-password-reset {
  background-color: $white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 1280px;
  margin: auto;
  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: 10px 70px;
    background-color: #ffffff;
    .header-col-left {
      width: 62%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        width: 216px;
        height: 46px;
        margin: 17px 0px;
      }
    }
    button {
      width: 185px;
      height: 35px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #ffffff;
      background: $color-primary;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
      border-radius: 2px;
      border: none;
      cursor: pointer;
      margin-left: 24px;
      margin-right: 50px;
      font-family: 'Lato', sans-serif;
    }
  }
  .body-wrapper {
    @include flex-column-center-center;
    padding: 20px;
    background-color: #ffffff;
    width: 100%;
    color: #353b7d;
    .password-header {
      margin-bottom: 50px;
    }
    form {
      max-width: 400px;

      .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
        border-color: $color-error;
      }

      .MuiInputBase-root {
        height: 40px;

        input {
          height: 30px;
        }
      }
      > div {
        margin-bottom: 15px;

        p {
          margin-left: 3px;
          color: $color-error;
          font-style: italic;
        }

        button {
          padding-left: 0;
          padding-right: 0;
          color: $color-primary;
        }
      }
    }

    .submit-btn {
      margin: 30px 0px;
      width: 100%;
      border: none;
      background: #4e55a2;
      height: 48px;
      box-shadow: $button-shadow;
      color: $white;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
