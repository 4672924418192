@mixin flex-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@mixin flex-start-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

@mixin flex-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-center-start {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

@mixin flex-center-end {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

@mixin flex-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

@mixin flex-around-center {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@mixin flex-between-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@mixin flex-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// COLUMN
@mixin flex-column-start-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@mixin flex-column-between-center {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-column-center-start {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

@mixin flex-column-center-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin flex-column-center-end {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

@mixin flex-column-end-center {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex-column-between-center {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
