@import '../../../../theme/main';

.status-element-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .colored-bullet {
    width: 15px;
    height: 15px;
    border-radius: 64px;
    color: white;
    text-align: center;
  }
  .status-label {
    margin-left: 10px;
  }
}
.plot-state-badge {
  height: 25px;
  min-width: 50px;
  line-height: 25px;
  border-radius: 64px;
  color: white;
  text-align: center;
}

.valid {
  background-color: $status-valid;
  color: #000;
}

.study {
  background-color: $status-study;
}

.cancel {
  background-color: $status-cancel;
}

.absent {
  background-color: $status-absent;
}

.contact {
  background-color: $status-contact;
}

.negotiation {
  background-color: $status-negotiation;
}

.signed {
  background-color: $status-signed;
}

.suspended {
  background-color: $status-suspended;
}

.signedByConcurrent {
  background-color: $status-signedByConcurrent;
}
