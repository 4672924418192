@import '../../../../theme/main';

.dashboard-tabs {
  display: flex;
  color: $color-primary;

  .dashboard-tab {
    height: 50px;

    &.selected {
      border-bottom: 2px solid $color-primary;
    }
  }
}
