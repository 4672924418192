@import '../../../../theme/main';

.askPluModalContent {
  p {
    line-height: 20px;
  }

  form {
    margin-top: 20px;
    div {
      margin: 10px 0;
      display: flex;

      label {
        margin-left: 10px;

        &.disabled {
          color: grey;
        }
      }
    }

    input[type='radio']:after {
      width: 12px;
      height: 12px;
      border-radius: 15px;
      top: 8px;
      // left: -1px;
      position: relative;
      background-color: white;
      content: '';
      display: inline-block;
      visibility: visible;
      border: 1px solid $color-primary;
      cursor: pointer;
    }

    input[type='radio']:checked:after {
      background-color: $color-primary;
    }

    .commentSection {
      flex-direction: column;

      textarea {
        border-color: $black;
        resize: none;
        &.error {
          border-color: red;
          background-color: rgb(250, 237, 237);
        }
      }
      label {
        margin-left: 0;
        margin-bottom: 10px;
      }

      .isError {
        color: red;
        font-size: 0.9em;
        font-style: italic;
      }
    }
  }
}
