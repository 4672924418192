@import '../../../../theme/main';

.addUserModalContainer {
  form {
    .textField {
      margin-top: 15px;
      .MuiFormControl-root {
        box-shadow: 0 0 3px 1px rgb(0 0 0 / 6%);
        border-color: #e4e6e8;
        background-color: #f1f4f8;

        .MuiOutlinedInput-root {
          border-radius: 5px;
          background: #ffffff;
          border: 1px solid #e4e6e8;

          &.Mui-error {
            background: $input-has-error-bkg;
          }

          &:hover {
            color: #4e55a2;
          }

          .MuiOutlinedInput-input {
            height: 30px;
            color: #4e55a2;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            background: transparent;
          }
        }
        .MuiInputLabel-outlined {
          color: #4e55a2;
          font-weight: 300;
          font-size: 14px;
          margin-left: 5px;
        }

        .MuiFormHelperText-root {
          color: $color-error;
          font-style: italic;
          font-size: 10px;
          margin-left: 3px;
          line-height: 12px;
        }
      }
    }
  }
}

.addUserFormSubmit {
  @include green-submit-btn;
  width: 300px;
  margin: 20px auto 20px auto;
  text-align: center;
}
