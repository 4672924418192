@import './functions';

//urbanease colors and fonts used
$color-primary: #4e55a2;
$color-success: #4fd211;
$color-warning: #e28f12;
$color-error: #d20000;
$link-color-blue: #1890ff;
$color-font-link: #648cff;

$background-grey: rgb(242, 242, 242);
$buttonGradient: linear-gradient(90deg, #ff6d4b 0%, #ffaa5f 130%);
$body-background: #e2e2e2;
$light-black: #424242;
$black: #000;
$white: #fff;
$logo-grey: #999999;
$gray: #828282;
$divider: #f4f4f4;
$grey-prospection-card: #ebebeb;

$priority-low: #4fd211;
$priority-middle: #d29c11;
$priority-hight: #d20000;
// status colors for parcel prospection
$status-valid: #4fd211;
$status-study: #d29c11;
$status-cancel: #d20000;
$status-absent: #455065;
$status-contact: #4e55a2;
$status-negotiation: #1564db;
$status-signed: #11b2f7;
$status-suspended: #e2da12;
$status-signedByConcurrent: #944bf2;

$folder-color-1: #4fd211;
$folder-color-2: #e21212;
$folder-color-3: #e28f12;
$folder-color-4: #e2da12;
$folder-color-5: #12e2bd;
$folder-color-6: #788e8a;
$folder-color-7: #11b2f7;
$folder-color-8: #1564db;
$folder-color-9: #944bf2;
$folder-color-10: #ed23f1;

$matrix-calculated-bg-color: #dcfdea;

$prospection-icon-primary-hovered: #292d55;
$prospection-icon-danger-hovered: #980808;

$heading-font: 'Lato', 'Branding-SemiBold', sans-serif;
$regular-fonts: 'Lato', sans-serif;
$thin-font: 'Lato', 'Helvetica Neue', Arial, sans-serif;
$primary-font:
  'Lato',
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  'Roboto',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Fira Sans',
  'Droid Sans',
  'Helvetica Neue',
  sans-serif;

// modal color
$modal-color-shadow: #828282;
$modal-color-background: #ffffff;
$modal-color-success: #4fd211;
$modal-color-warning: #e28f12;
$modal-color-error: #d20000;
$modal-color-info: #4e55a2;

// input has error
$input-has-error-border: #ff0000;
$input-has-error-bkg: #ffecfc;

// urba gpt
$urba-gpt-background-question: #e9ffdd;
$urba-gpt-open-button: #fff22f;

//colors
$grey: #eee;
$red: #f00;
$ghost-white: #f8f8ff;
$blue-light: #bbc7f0;
$blue-select-prediction: #ced9fd;
$light-steel-blue: #bac6f2;
$light-steel-blue-hover: #3861f6;
$field-border: 1px solid rgb(180, 177, 177);
$head-text: #555980;
$light-gray: #f1f4f8;
$light-tab-gray: #777777;
$light-text: #5e5f5f;
$dark-red: #d2112f;
$red: #ff0000;
$button-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
$box-shadow: 0 4px 24px 0 rgba(37, 38, 94, 0.1);
$gray-border: #dfdfdf;
$light-gray-bg: #ededed;
$light-purple: #353b7d;
$gray-bg: #f6f6f6;

$sider-grey: #bdbdbd;
$headers-color: #00a8d2;
$left-panel-color: #007d9d;
$footer-color: #00a8d2;
$resident-sidebar: #eaf2f2;
$resident-button-border-grey: #bbbbbb;

$grey-color: #666666;
$dark-opacity: rgba(135, 158, 190, 0.7);
$dark-grey: #ddd;
$darker-grey: #333;
$light-grey-color: #fafafa;
$lighter-grey: #ccc;
$blue: #00a8d2;
$light-blue: #00b0d6;
$dark-blue: #52a5d0;
$blue-green: #28f8cb;
$orange: #ff6d4b;

$base-font-color: $grey-color;
$header-font-color: $white;
$action-color: $blue;

// Border
$base-border-color: $dark-grey;
$base-border: 1px solid $base-border-color;
$light-border: 1px solid $lighter-grey;
/*=================*/

$minResolution: 1280px;
$midResolution: 1440px;
$maxResolution: 1800px;

//Ant overrides
$font-family: Helvetica;

//responnsive carousel
$carouselWidthMin: 530px;
$carouselWidthMid: 590px;
$carouselWidthMax: 800px;

$carouselHeightMin: 300px;
$carouselHeightMid: 331px;
$carouselHeightMax: 450px;

$secondaryMenuColor: #32375f;

//header height
$headerHeight: 58px;

$small-font-size: 13px;
$regular-fonts-size: 15px;
$header-menu-fonts-size: 17px;
$header-menu-line-height: 17px;

$base-line-height: 1;
$medium-line-height: 1.1;
$heading-line-height: 1.2;
$large-line-height: 1.6;

$base-spacing: 10px;
$third-spacing: calc($base-spacing / 3);
$half-spacing: calc($base-spacing / 2);
$base-and-third-spacing: calc($base-spacing + $third-spacing);
$base-and-half-spacing: calc($base-spacing + $half-spacing);
$double-spacing: calc($base-spacing * 2);
$triple-spacing: calc($base-spacing * 3);
$quad-spacing: calc($double-spacing * 2);

// BUTTONS
$base-button-height: 40px;
$base-button-font: 14px;
$base-button-line-height: $medium-line-height;

$primary-button-bg: $blue;
$primary-button-cancel-bg: $gray;
$primary-button-color: $white;
$secondary-button-bg: $light-grey-color;
$tertiary-button-bg: $white;

$toolbar_hover_bg: #ddd;
$toolbar_active_bg: #cfc;

// TABLE
$table-row-color: #334660;
$row-border-botom-color: #e0e0e0;
$cell-hover-bg-color: #e0e0e0;

$colorList: (
  'Primary': $color-primary,
  'DarkPrimary': $prospection-icon-primary-hovered,
  'Red': $color-error,
  'Error': $color-error,
  'DarkError': $prospection-icon-danger-hovered,
  'Green': $color-success,
  'Success': $color-success,
  'Orange': $color-warning,
  'Warning': $color-warning,
  'Black': $black,
  'Grey': $grey,
  'White': $white,
  'Transparent': transparent,
);
