@import '.././../../../theme/main';

.contactFromComponents {
  width: 100%;

  .oldAddress {
    margin: 0;
    margin: 10px 0;
  }

  > div {
    margin-bottom: 5px;
  }

  .flexRow {
    @include flex-center-center;

    > div {
      flex: 1;
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .addressLineContainer {
    .oldAddress {
      text-align: left;
      margin: 0;
      margin: 10px 0;
      span {
        color: $sider-grey;
      }
    }

    > div {
      margin: 5px 0;
    }
  }
}
