@import '../../../../theme/main';

.dateFilterContainer {
  margin-top: 10px;

  p {
    margin: 0 0 5px 0;
    width: 100%;
  }

  .dateFilter {
    @include flex-center-center;
    width: 100%;

    > div {
      input {
        font-family: 'Lato', sans-serif;
        font-size: 12px;
        padding: 5px;
        border: 1px solid $body-background;
        color: $grey-color;
        border-radius: 5px;
      }
    }

    .endSpace {
      margin: 0 5px;
    }
  }
}
