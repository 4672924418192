@import '../../../../../theme/main';

.cesiumFatalError {
  width: 100%;
  height: 100%;
  @include flex-center-center;
  flex-direction: column;

  .cesiumViewerContainer {
    height: 0;
  }

  p {
    font-size: 1.5em;
    text-align: center;
    line-height: 1.5em;
  }
  button {
    @include button-base-blue;
    width: 300px;
    margin-top: 50px;
  }
}
