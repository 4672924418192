@import '../../../../theme/main';

.loginModalContent {
  @include flex-column-center-center;
  margin-top: -20px;
  margin-bottom: 30px;
  width: 100%;

  .padlock {
    width: 40px;
    object-fit: contain;
  }

  .footerWrapper {
    width: 80%;
    text-align: center;

    .leftText {
      color: $light-text;
      font-size: 14px;
    }

    .rightText {
      color: $dark-red;
      font-size: 14px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.loginForm {
  @include flex-column-center-center;
  width: 100%;

  > div {
    &:first-child {
      width: 100%;
    }
    input {
      padding: 10px 5px;
    }
  }

  .submitButton {
    @include button-base-blue;
    margin-top: 20px;
    height: 50px;
    font-size: 1.1em;
    padding: 0 20px;
  }
}
