@import '../../../../../../../../../theme/main';

.parkingPackages {
  ul {
    margin: 0;
    margin-top: 20px;
    padding: 0;
    list-style: none;
  }
}
