@import '../../../theme/main';

.viewPageContainer {
  width: 100%;

  .content {
    margin-top: 55px;
    height: calc(100vh - 55px);
  }
}
