@import '../../../../../theme/main';

.passwordForm {
  .field {
    margin-bottom: 4px;
  }

  .submitBtn {
    margin-top: 20px;
    background: $light-steel-blue;
    border-radius: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    color: $color-primary;
    border: none;
    width: 100%;
    cursor: pointer;

    height: 45px;

    &:hover {
      padding: 14px 0px;
      border: 1px solid $light-steel-blue-hover;
    }

    &:active {
      box-shadow: 0 0 5px $light-steel-blue-hover;
      font-weight: 700;
    }
  }
}
