@import '../../../theme/main';

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownButton {
  @include flex-center-center;
  background-color: $color-primary;
  color: $white;
  padding: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.burgerIcon {
  width: 24px;
  height: 24px;
}

.dropdownMenu {
  position: absolute;
  top: 37px;
  right: 0;
  background-color: $white;
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 184px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 0;
}

.dropdownItem {
  display: flex;
  align-items: center;
  list-style: none;
  color: $light-purple;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    color: $dark-red;
  }

  &:hover {
    background-color: #f5f5f5;
  }

  &:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
