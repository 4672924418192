@import '../../../../theme/main';

.folderSection {
  margin-bottom: 10px;

  .filter {
    margin-bottom: 10px;
    > div {
      margin-top: 5px;
    }

    .buttons {
      @include flex-center-center;
      margin-top: 5px;

      button {
        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }

  .folderContainer {
    .noElement {
      text-align: center;
    }
  }
}
