@mixin modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.5);
  opacity: 1;
  z-index: 1010;
}
@mixin modal-base {
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  padding: 20px;

  z-index: 1011;

  .closeIconContainer {
    position: absolute;
    top: 5px;
    right: 5px;

    width: 100%;
    @include flex-end-center;
    .closeIcon {
      padding: 0;
    }
  }
}
