@import '../../../../theme/main';

.accountTabs {
  display: flex;
  color: $color-primary;

  .accountTab {
    height: 50px;

    &.selected {
      border-bottom: 2px solid $color-primary;
    }
  }
}
