@import '../../../../theme/main';

.dashboardLoader {
  .loader {
    margin-top: 50px !important;
  }

  p {
    margin-top: 40px;
    text-align: center;
  }
}
