@import './../../../../../theme/main';

.contactContent {
  .contactListContainer {
    @include flex-column-center-center;

    .contactList {
      margin-top: 5px;
      width: 100%;
      @include flex-column-center-center;

      .contactCard {
        display: flex;
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;
        border-radius: 15px;
        box-shadow: $box-shadow;

        .leftBlock {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 30%;
          border-left: 5px solid $grey-prospection-card;
          padding-left: 5px;
          min-height: 127px;

          p {
            margin: 0;
            margin-bottom: 5px;
          }

          a {
            color: #4e55a2;
          }

          .contactName {
            font-weight: 700;
          }
        }

        .centerBlock {
          flex: 1;
          @include flex-start-center;
          margin: 0 10px;
          width: 40%;
        }

        .rightBlock {
          display: flex;
          flex-direction: column;
          align-self: flex-start;

          .contactActions {
            display: flex;
            flex-direction: column;
            align-self: flex-end;
            min-height: 90px;

            .buttonContactActions {
              display: flex;
              justify-content: flex-end;
              margin-bottom: 15px;
              img {
                margin: 0 5px;
                cursor: pointer;
              }
            }
            .switchContact {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              margin-bottom: 15px;

              p {
                margin: 0;
              }
            }
          }
          .urbaGpt {
            @include flex-center-center;
            &Logo {
              position: absolute;
              right: 0;
              width: 48px;
              height: 48px;
              margin-top: 19px;
            }
            &BtnLogo {
              display: flex;
              align-items: center;
              cursor: pointer;
              border: none;
              color: $white;
              background: $color-error;
              padding: 10px;
              padding-right: 20px;
              margin-left: 5px;
              width: auto;
              min-width: 265px;
              border-radius: 5px;
              position: relative;
              overflow: hidden;
            }
            &Btn {
              display: flex;
              align-items: center;
              cursor: pointer;
              border: none;
              color: $white;
              background: $color-error;
              padding: 10px;
              margin-left: 5px;
              width: auto;
              border-radius: 5px;
            }
            &BtnDisabled {
              opacity: 0.6;
              cursor: default;
            }
            .arrowBack {
              color: $color-error;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .contactForm {
    .Mui-error {
      fieldset {
        border-color: $color-error;
      }
    }
    > div {
      margin: 15px 0;
    }

    p {
      margin: 3px 0 0 5px;
    }
    .topBlock {
      @include flex-between-start;

      > div {
        width: 24%;
      }
    }

    .centerBlock {
      @include flex-between-start;

      > div {
        width: 45%;
      }
    }

    .bottomBlock {
      .contactInfos {
        &:focus-visible {
          outline: none;
        }

        width: 100%;
        padding: 10px;
        background-color: white;
        border: 1px solid grey;
        border-radius: 4px;
      }
    }

    .row {
      @include flex-center-center;
    }
  }
}
