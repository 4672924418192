@import '../../../../theme/main';

.edit-folder-modal {
  .MuiPaper-rounded {
    border-radius: 10px !important;
    max-width: 590px;
    width: 590px;
    margin: 0px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.565068);
  }
  .edit-folder-modal-container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px !important;
    position: relative;
    height: 424px;
    background: #ffffff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.565068);
    align-items: flex-start;
    border: none;
    .xicon-wrapper {
      cursor: pointer;
      position: absolute;
      top: 18px;
      right: 20px;
      svg {
        font-size: xx-large;
        fill: #bababa;
        width: 28px;
        height: 28px;
      }
    }
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 19px;
      line-height: 23px;
      color: #4e55a2;
      letter-spacing: normal;
      text-transform: none;
      margin-top: 0px;
    }
    .text-field {
      width: 439px;
      height: 44px;
      margin-top: 20px;
      input {
        border-radius: 4px;
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        color: #354052;
        padding: 7px 14px;
      }
    }
    .sub-title {
      font-style: normal;
      font-weight: bold;
      font-size: 19px;
      line-height: 23px;
      color: #4e55a2;
      margin-top: 20px;
    }
    .pin-icons {
      display: flex;
      margin-top: 20px;
      img {
        margin-right: 15px;
      }
    }
  }
  .pin-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    .ajouter-btn {
      background: #75cc8b;
      border-radius: 2px;
      width: 237px;
      height: 48px;
      cursor: pointer;
      margin-top: 22px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #ffffff;
      border: none;
    }
  }
}
