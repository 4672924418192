@import '../../theme/main';

.account {
  background: linear-gradient(to bottom, #4e55a2, #a2a5d1);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;

  .mainWrapper {
    min-height: 100vh;
    max-width: 1280px;
    padding: 0 10px;
    margin: auto;
    display: flex;
    flex-direction: column;

    .urbnLoaderWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: hsla(120, 13%, 10%, 0.7);
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 10000;
      height: 100vh;
    }

    .header {
      margin-bottom: 30px;
      background-color: $white;
      display: flex;
      height: 70px;
      align-items: center;
      padding: 0 70px;
      justify-content: space-between;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid #e0e0e0;
      margin-bottom: 20px;
      border-radius: 0 0 10px 10px;

      .headerColLeft {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img {
          width: 216px;
          height: 46px;
          margin: 17px 5px;
          cursor: pointer;
        }
      }

      .headerColRight {
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 20px;

        .user {
          font-style: normal;
          color: $light-purple;
          font-family: 'Lato', sans-serif;
          font-size: 14px;
          margin: 0 0 5px 0;
        }
        .badge {
          display: inline-block;
          background-color: $light-purple;
          color: $white;
          font-size: 12px;
          font-weight: 600;
          padding: 4px 8px;
          margin: 0;
          border-radius: 12px;
          text-align: center;
        }

        .goBack {
          @include flex-center-center;
          @include button-base-blue;
          height: 35px;
          padding: 0 15px;

          svg {
            fill: #ffffff;
            margin-left: 5px;
            height: 25px;
          }
        }
      }
    }

    .body {
      background-color: $white;
      flex: 1;
      border-radius: 10px 10px 0 0;

      .bodyWrapper {
        padding: 15px 70px 20px 70px;
        background-color: $white;

        .MuiAppBar-colorDefault {
          background-color: $white;
          box-shadow: none;
        }

        .MuiTab-textColorPrimary {
          color: $color-primary;
          text-transform: capitalize;
        }
      }
    }
    .footer {
      background-image: url('../../assets/images/diagonal.png');
      height: 200px;
      background-position: center;
      background-color: $white;
      width: 100%;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;

      .innerFooter {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;

        h3 {
          font-style: normal;
          font-weight: 800;
          font-size: 24px;
          line-height: 29px;
          text-align: center;
          color: $light-purple;
          margin: 0px;
        }

        .legalMentionsButton {
          color: $color-primary;
          margin: 10px;
          text-decoration: none;
          border-radius: 5px;
          padding: 10px;
          width: 220px;
        }

        .innerPara {
          font-style: normal;
          font-weight: 300;
          font-size: 21px;
          line-height: 24px;
          text-align: center;
          color: $head-text;
          margin: 5px 0px 20px;
        }

        button {
          @include button-base-blue;
          width: 271px;
        }
      }
    }
  }
}
