@import '../../../../theme/main';

.buttonGeneric {
  height: 40px;
  width: 200px;

  > div {
    height: 100%;
    width: 100%;
    button {
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      border-radius: 2px;
      height: 100%;
      width: 100%;
      box-shadow:
        0 3px 1px -2px rgba(0, 0, 0, 0.2),
        0 2px 2px rgba(0, 0, 0, 0.14),
        0 1px 5px rgba(0, 0, 0, 0.12);
    }
  }
}
.tooltip {
  p {
    margin: 0;
    text-align: center;
  }
}
