@import './../../../../../theme/main';

.procedure-content {
  .procedure-list {
    > div {
      margin-bottom: 10px;
    }

    .procedure-card {
      @include flex-center-center;

      .left-block {
        flex-grow: 1;
        margin: 0 10px 0 0;
        padding: 0 10px;
        color: $gray;

        .header {
          @include flex-between-center;
          font-weight: 600;
          font-size: 16px;
          align-items: center;
          img {
            margin: 0 10px;
          }
        }
        .body {
          font-weight: 300;
        }
        .procedure-icon {
          height: 22px;
          width: 22px;
        }
      }

      .right-block {
        display: flex;
        justify-content: flex-end;
        align-self: stretch;

        img {
          height: 22px;
          margin: 0 5px;
          cursor: pointer;
        }
      }
    }
  }

  .procedure-form {
    > div {
      width: 100%;
      margin: 15px 0;
    }
    .Mui-error {
      fieldset {
        border-color: $color-error;
      }
    }
    .top-block {
      @include flex-between-center;

      > div {
        width: 24%;
      }

      p {
        margin: 3px 0 0 5px;
        color: $color-error;
        font-size: 0.75rem;
      }

      .date-picker {
        width: 18%;

        .MuiOutlinedInput-input {
          padding-left: 13px;
        }
      }
    }

    .bottom-block {
      .procedure-comment {
        width: 100%;
        padding: 10px;
        background-color: white;
        border: 1px solid grey;
        border-radius: 4px;

        &:focus-visible {
          outline: none;
        }
      }
    }
  }
}
