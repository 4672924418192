@import '../../../../../../../../../theme/main';

.fiscalZone {
  p {
    font-weight: 600;
    margin: 10px 0;

    span {
      font-weight: 400;
    }

    .zoneInfo {
      font-style: italic;
      font-size: 0.9em;
    }
  }
}
