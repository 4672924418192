@import '../../../../../theme/main';

.inputTabsWrapper {
  background: $white;
  display: flex;
  flex-direction: column;
  padding: 20px;
  transition: min-height 0.3s;
  // min-height: 184px;
  // height: 184px;
  border-radius: 10px;

  &.inputPlotVisible {
    min-height: 300px;
    height: 300px;
  }

  h2 {
    color: $light-purple;
    font-size: 28px;
    font-weight: normal;
    margin: 0;
  }

  .tabsContent {
    display: flex;

    .fieldContainer {
      margin: 6px 0;
      flex-grow: 1;

      .autocompleteContainer {
        position: relative;
      }

      .plotInputContainer {
        height: 108px;
        display: none;

        &.visible {
          animation: input-plot-visible 0.3s linear;
          display: block;
        }
      }

      .field-item {
        margin: 10px 0;
      }

      .plotInput {
        margin: 10px 0;
      }
      .hasError {
        fieldset {
          border-color: $input-has-error-border !important;
        }
        input {
          background-color: $input-has-error-bkg;
        }
        p {
          color: $color-error;
        }
      }

      .submitBtn {
        width: 100%;
      }
    }

    .iconContainer {
      padding: 13px 0 0 10px;
      display: flex;
      justify-content: center;

      width: 40px;
      height: 80px;

      svg {
        cursor: pointer;
        color: $color-primary;
        width: 30px;
        height: 30px;
      }

      &.openned {
        svg {
          background-color: $color-primary;
          color: white;
          border: 4px solid $color-primary;
          border-radius: 5px;
          margin-top: -2px;
        }
      }
    }
  }
}
