@import '../../../../theme/main';

.priorityIcon {
  display: inline;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 16px solid transparent; /* Changez la couleur de fond selon vos besoins */
  position: relative;
  color: $white;

  &.bigger {
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-bottom: 28px solid transparent; /* Changez la couleur de fond */
    margin-right: 0px;
    margin-left: 5px;

    .content {
      font-weight: 700;
      font-size: 0.85em;
      position: absolute;
      left: -14px;
      top: 6px;
      width: 28px;
      height: 28px;
      @include flex-center-center;
    }
  }
  &.green {
    border-bottom-color: $priority-low;
  }
  &.orange {
    border-bottom-color: $priority-middle;
  }
  &.red {
    border-bottom-color: $priority-hight;
  }
}

.priorityIconWithTooltip {
  position: relative;
  @include flex-center-center;

  .tooltip {
    position: absolute;

    left: calc(-50% - 5px);
    top: -30px;
    display: none;
    border-radius: 5px;
    padding: 3px 5px;
    color: $white;
    font-size: 0.8em;

    &.green {
      background-color: $priority-low;
      color: $black;
    }
    &.orange {
      background-color: $priority-middle;
      left: calc(-50% - 10px);
    }
    &.red {
      background-color: $priority-hight;
      left: calc(-50% - 7px);
    }
  }

  &:hover {
    .tooltip {
      @include flex-column-center-center;
    }
  }
}
