@import '../../../../theme/main';

.loginContent {
  @include flex-column-center-center;
  width: 100%;

  h3 {
    margin-bottom: 20px;
    margin-top: 25px;
    text-align: center;
    font-size: 1.1rem;
    color: $white;

    &.blueTitle {
      color: $color-primary;
    }
  }

  .forgotPassword {
    color: $link-color-blue;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 10px;
  }
}
