@import '../../../../theme/main';

.glassButton {
  &:active {
    // background-color: white;
    img {
      width: 40px;
    }
  }
  cursor: pointer;
  border-radius: 6px;
  overflow: hidden;
  background-color: $color-primary;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 25px;
  }
}
