@import '../../../../theme/main';

.read-more-modal {
  .MuiPaper-rounded {
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.565068);
    max-width: 690px;
    width: 690px;
    padding: 40px;

    .read-more-modal-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      overflow: hidden;
      background: #ffffff;
      color: #4e55a2;

      .title {
        font-weight: bold;
        font-size: 19px;
        margin-top: 20px;
      }

      .content-wrapper {
        width: 100%;
        margin-top: 20px;
        overflow: auto;
        font-size: 16px;
        font-weight: 600;

        .data-list {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            padding: 2px 0;
            &::before {
              content: '';
              height: 0.4em;
              width: 0.4em;
              background: #2e368d;
              display: inline-block;
              margin-right: 15px;
              transform: rotate(45deg);
              margin-left: 1px;
            }
          }
        }
      }
    }
  }
}
