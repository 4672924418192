@import '../../../theme/main';

.deleteWithConfirmationModal {
  p {
    line-height: 20px;
    span {
      font-weight: 700;
    }
  }
}
