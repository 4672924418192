@import '../../theme/main';

.dashboard {
  overflow: auto;
  background: linear-gradient(to bottom, #4e55a2, #a2a5d1);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;

  .main-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 1280px;
    padding: 0 10px;
    margin: auto;

    .header {
      background-color: $white;
      display: flex;
      height: 70px;
      align-items: center;
      padding: 0 70px;
      justify-content: space-between;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid #e0e0e0;
      margin-bottom: 20px;
      border-radius: 0 0 10px 10px;

      .header-col-left {
        width: 62%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img {
          width: 216px;
          height: 46px;
          margin: 17px 5px;
          cursor: pointer;
        }
      }
      button {
        @include button-base-blue;
        height: 35px;
        margin-right: 50px;
        padding: 0 15px;

        svg {
          fill: $white;
          margin-left: 5px;
        }
      }
    }
    .MuiTabs-root {
      padding: 0 70px;

      .MuiTabs-flexContainer {
        > button {
          width: 20%;
        }
      }
    }
    .MuiAppBar-colorDefault {
      background-color: $white;
      box-shadow: none;
    }

    .MuiTab-textColorPrimary {
      color: #4e55a2;
      text-transform: capitalize;
    }

    .body {
      background-color: $white;
      flex: 1;
      border-radius: 10px 10px 0 0;

      .body-wrapper {
        padding: 15px 70px 20px 70px;
        background-color: $white;
        width: 100%;

        .MuiTableCell-root {
          text-align: center;
        }

        > div {
          height: 100%;
        }
        .MuiBox-root {
          padding: 0;
        }
        th {
          background-color: #e4e6e8;
        }

        .clickable {
          cursor: pointer;
          font-weight: 700 !important;
          text-decoration: underline;

          &:hover {
            background-color: $background-grey !important;
          }
        }

        .row {
          @include table-row-border-bottom;

          .MuiTableCell-root {
            padding-top: 8px;
            padding-bottom: 8px;
          }
          td {
            border-bottom: none !important;
            font-style: normal !important;
            font-weight: 500;
            font-size: 12px !important;
            color: $table-row-color !important;
          }
        }
      }
    }
  }
}

.no-plot-studies {
  text-align: center;
  padding: 10px 0;
  font-weight: 600;
  color: $black;

  &.p-max {
    width: 100%;
  }
}

.filter-container-plot {
  display: flex;
  justify-content: flex-end;
}
