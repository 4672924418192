@import '../../../../theme/main';

.rightPanelModal {
  font-family: $regular-fonts;
  position: absolute;
  overflow-x: hidden;
  top: 0;
  right: -550px;
  height: 100vh;
  width: 550px;
  z-index: 999;
  background-color: $white;
  border-left: 1px solid $color-primary;

  &.displayed {
    right: 0;
  }

  .arrowWrapper {
    position: fixed;
    z-index: 1;
    margin-top: 95px;
    cursor: pointer;
    .arrow {
      height: 30px;
      position: absolute;
      left: -62px;
      rotate: 90deg;
    }
  }

  // title on blue bg
  .rightPanelModalContentHeader {
    width: 100%;
    @include flex-column-center-center;
    color: $white;
    background-color: $color-primary;
    height: 45px;
    margin-top: 55px;
    h2 {
      color: $white;
    }
  }

  .rightPanelModalContent {
    width: 100%;
    height: calc(
      100vh - 100px
    ); /* 100px is rightPanelModalContentHeader full width */
    display: flex;
    flex-direction: column;

    form {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      flex: 1;
      overflow-y: hidden;
      padding: 0 20px;
      h3 {
        margin: 10px 0;
        color: $color-primary;
      }
      p {
        margin: 5px 0;
      }

      &.hidden {
        visibility: hidden;
        height: 0;
        overflow: hidden;
      }

      textarea {
        color: #354052;
        font-size: 14px;
        font-weight: 300;
        resize: none;
        width: 100%;
        &.error {
          border: 1px solid red;
          outline: 1px solid red;
          background-color: $input-has-error-bkg;
        }
      }

      .plotForm {
        flex: 1;
        overflow-y: auto;
      }
      .rowInput {
        @include flex-center-center;
        padding-left: 20px;
        width: 100%;
        p {
          min-width: 165px;
          margin: 0;
        }
      }
      .rowIcon {
        display: flex;
        align-items: center;
        > img {
          padding-right: 20px;
          width: 50px;
        }
        .rowInput {
          border-left: 2px #679cc6 solid;
          height: 100%;
        }
      }
      .multiCustomers {
        margin: 10px 0;

        p {
          margin: 0;
          margin-bottom: 5px;
        }
        ul {
          padding-left: 0;
          margin: 0 auto;

          li {
            @include flex-center-center;
            margin: 3px 0;

            button {
              margin: 0 10px;
            }
          }
        }

        button {
          @include button-base-blue;
          font-size: 0.9em;
          height: 26px;
          margin-top: 5px;
        }
      }
      .columnInput {
        margin-bottom: 5px;
        .spacing {
          margin-bottom: 5px;
        }
      }
      .commentContainer {
        background-color: #726fad1a;
        padding: 5px 10px;
        margin: 5px 0;

        p {
          span {
            font-size: 0.8em;
            font-style: italic;
          }
        }
      }
      .checkboxContainer {
        display: flex;
        flex-wrap: wrap;
      }
      .inputForm {
        display: flex;
        align-items: center;
        width: 100%;
        svg {
          margin-left: 5px;
        }
      }

      .formSectionLandInfo {
        @include flex-center-start;
        padding-left: 20px;
        .leftSectionBloc {
          width: 50%;
          @include flex-start-start;
          flex-direction: column;
        }
        .rightSectionBloc {
          width: 50%;
          @include flex-start-center;
          flex-direction: column;
          margin-left: 20px;
        }
      }

      .buttonContainer {
        @include flex-center-center;
        height: 60px;
        margin-top: 20px;
        button {
          margin: 5px;
          padding: 0;
        }
        .button {
          @include button-base-blue;
          width: 185px;

          &.disabled {
            opacity: 0.6;
          }

          &.red {
            background-color: $color_error;
            border: none;
          }
        }
      }
    }

    .progressBarContainer {
      height: calc(100vh - 100px);
      display: flex;
      align-items: center;
    }

    .noPlotContent {
      height: 100%;
      @include flex-column-center-center;
      font-size: 1.5em;
      color: $color-primary;
      text-align: center;

      p {
        margin: 10px;
        padding: 0;
      }
    }
  }
}
