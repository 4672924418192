@import '../../../../theme/main';

.georisqueLegend {
  @include environment-container;

  p {
    margin: 0;
  }

  input {
    cursor: pointer;
  }

  .legendSection {
    .legendSectionHeader {
      width: 100%;
      display: flex;
      align-items: center;

      .headerCheckbox {
        @include flex-center-center;
        flex-grow: 1;

        h3 {
          margin: 0;
          padding-left: 5px;
          font-size: 0.9rem;
          flex-grow: 1;
        }
      }
      .expandIcon {
        cursor: pointer;

        &.isOpen {
          transform: rotate(180deg);
        }
      }
    }

    .sectionCheckboxContainer {
      padding-left: 10px;
      .sectionCheckboxRow {
        display: flex;
        flex-direction: column;

        .rowHeader {
          @include flex-start-center;

          label {
            font-size: 0.9em;
            padding-left: 5px;
          }
        }

        .rowLegend {
          img {
            max-width: 100%;
          }

          p {
            padding-left: 20px;
            font-size: 0.8rem;
            font-style: italic;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
