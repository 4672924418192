@import '../../../../theme/main';

.noUrbaneaseAccountModal {
  width: 100%;

  .body {
    width: 100%;
    p {
      margin: 0;
      line-height: 20px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        width: 50%;
      }
    }
    margin-bottom: 40px;
  }
  .btnContainer {
    width: 100%;
    @include flex-between-center;

    .button {
      flex: 0.33;
      height: 60px;
    }

    .supportBtnContent {
      @include flex-center-center;
    }

    .pdfBtnContent {
      @include flex-center-center;
      svg {
        fill: $white;
        margin-left: 5px;
        height: 25px;
        width: 30px;
      }
    }

    .accountIcon {
      color: white;
      width: 20px;
      height: 20px;
      margin: 0 8px;
    }
  }
}
