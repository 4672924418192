@import '../../../../theme/main';

@mixin base-message {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin: 0 0 20px 0;
  }
}
.pluLegend {
  @include environment-container;

  &.noPlu {
    @include base-message;
  }

  &.loading {
    @include base-message;

    p {
      margin-bottom: 10px;
      font-size: 1.1em;
      &:first-child {
        margin-top: 30px;
      }
    }
  }

  &.pluStatus {
    text-align: center;
    h4 {
      margin: 40px 0;
    }

    .status {
      font-weight: 700;
    }
  }
  .checkboxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    // margin-top: 20px;
  }

  .pluButton {
    margin-top: 50px;
    width: 100%;
  }
}

.pluError {
  @include environment-container;
}
