@keyframes input-plot-visible {
  from {
    height: 0;
    transform: scaleY(0);
    transform-origin: top;
  }
  to {
    height: auto;
    transform: scaleY(1);
    transform-origin: top;
  }
}

@keyframes scale-bottom-right {
  0% {
    transform: scale(0);
    opacity: 0;
    transform-origin: 75% 100%;
  }

  100% {
    transform: scale(1);
    opacity: 1;
    transform-origin: 75% 100%;
  }
}

@keyframes scale-bottom-right-reverse {
  0% {
    transform: scale(1);
    opacity: 1;
    transform-origin: 75% 100%;
  }

  100% {
    transform: scale(0);
    opacity: 0;
    transform-origin: 75% 100%;
  }
}

@keyframes urba-gpt-open {
  0% {
    transform: scale(0);
    opacity: 0;
    transform: rotate(-25deg);
  }

  100% {
    transform: scale(1);
    opacity: 1;
    transform: rotate(0deg);
  }
}

@keyframes urba-gpt-close {
  0% {
    transform: scale(0);
    opacity: 0;
    transform: rotate(25deg);
  }

  100% {
    transform: scale(1);
    opacity: 1;
    transform: rotate(0deg);
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: $grey-color;
  }
}

@keyframes loader-animation {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
