.pagination {
  margin-top: 20px;
  display: flex;
  text-align: left;
  align-items: center;
  height: 50px;

  nav {
    flex-grow: 1;
    display: flex;
    justify-content: center;

    ul {
      list-style-type: none;
    }
  }

  .itemsPerPage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;

    span {
      font-weight: 400;
      font-size: 0.9em;
    }
    > div {
      margin-left: 5px;
      width: 60px;
    }

    .MuiFormControl-root {
      border: none;
      background-color: transparent;
      box-shadow: none;
    }

    .MuiOutlinedInput-input {
      padding: 5px 0px 5px 10px;
    }
  }
}
