@import '../../../theme/main';

@mixin mailshot-modal-base {
  @include modal-base;
  @include flex-column-center-center;
  color: $color-primary;
  background-color: $white;
}

@mixin mailshot-modal-btn-container {
  @include flex-center-center;
  margin-top: 20px;

  button {
    max-width: 48%;
    width: 300px;

    &:first-child {
      margin-right: 10px;
    }
  }
  .btnValid {
    @include button-base-blue;
  }
  .btnCancel {
    @include button-base-red;
  }
}

@mixin mailshot-contact-table {
  .contactTableTitle {
    margin: 5px 0;
    color: $color-primary;
  }
  .contactTable {
    margin: auto;
    tr {
      td {
        &:nth-child(1),
        &:nth-child(2) {
          width: 10px;
        }
        &:nth-child(3),
        &:nth-child(4) {
          width: 20%;
        }
        &:nth-child(5) {
          width: 30%;
        }
        &.validContactColumn {
          text-align: center;
          color: $color-success;

          &.notValid {
            color: $color-error;
          }
        }
      }
    }
  }
}

.successContentWith {
  width: 500px !important;
}

.modalBase {
  h3,
  h4 {
    margin: 20px 0;
    text-align: center;
  }

  p {
    margin: 0;
    text-align: center;
  }

  div,
  form {
    width: 100%;
  }

  table {
    border-collapse: collapse;

    width: 99%;
    margin: auto;
    cursor: default;
    thead {
      background-color: #e4e6e8;
    }

    th {
      color: $color-primary;
    }
    td,
    th {
      height: 40px;
      padding: 0 5px;
      text-align: center;
    }

    td {
      color: $table-row-color;

      .index {
        @include flex-center-center;
        padding: 2px;
        border-radius: 50%;
        background-color: $color-primary;
        color: $white;
        font-weight: bold;
        font-size: 12px;
        width: 20px;
        height: 20px;
      }

      .warningIcon {
        padding: 2px;
        cursor: default;
      }
      &.iconCell {
        width: 15px;
        cursor: pointer;
        padding: 0 2px;

        .iconInCell {
          padding: 0;
        }
        .contactIconContainer {
          @include flex-center-center;

          .iconInCell {
            padding: 0;

            &.isOpen {
              svg {
                path,
                rect,
                circle {
                  fill: green;
                }
              }
            }
          }
        }
      }
    }

    tbody {
      tr {
        @include table-row-border-bottom;
      }
    }
  }

  .mailshotActionModal {
    width: 300px;

    .btnContainer {
      @include mailshot-modal-btn-container;
    }
  }

  .mailshotSelectionResumeModal {
    max-height: 600px;
    @include flex-column-center-center;

    .mailshotSelectionResumeContent {
      overflow-y: scroll;
      flex: 1;
      @include flex-column-start-center;

      .modelChoiceContainer {
        @include flex-center-center;
        > div {
          width: 50%;
          margin-left: 20px;
        }
      }
      .mailshotFolderContactList {
        @include mailshot-contact-table;
      }
      .plotList {
        .plotListTitle {
          @include flex-center-center;
          > div {
            width: inherit;
          }
        }
        .plotTable {
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
          .contactInfoColumn {
            .contactCount {
              &.noContact {
                color: $color-error;
              }
              &.allAddressesOk {
                color: $color-success;
              }
            }
            .invalidContactCount {
              color: $color-error;
            }
          }
          > tbody {
            .mailshotPlotRowContactTable {
              > td {
                padding: 10px 0 10px 20px;
                @include mailshot-contact-table;
              }
            }
          }
        }
      }
    }
  }

  .mailshotResumeSuccessContent {
    @include flex-column-center-center;

    p {
      color: $color-primary;
      line-height: 20px;
    }

    .body,
    section {
      @include flex-column-center-center;
    }

    .body {
      padding: 20px 30px;

      section {
        margin-bottom: 20px;

        .checkIcon {
          margin-bottom: 20px;
        }
      }
    }
  }

  .outOfRange {
    color: $color-error;
  }

  .contactFormModal {
    margin: auto;
    max-width: 90%;

    .btnContainer {
      @include mailshot-modal-btn-container;
    }
  }
}

.genericModalOverride {
  h2 {
    margin: 10px 0 !important;
    font-size: 1.5em !important;
  }

  .mailshotActionModalContent {
    .deleteContent {
      height: 100px;
      @include flex-column-center-center;
      p {
        line-height: 25px;
        margin: 0;
      }
    }

    .updateContent {
      @include flex-column-center-center;
      p {
        line-height: 25px;
        margin: 0;
      }
    }
  }
}

.expandMoreIcon {
  color: $color-primary;

  &.down {
    transform: rotate(180deg);
  }
}

.centerTooltipContent {
  text-align: center;
  line-height: 15px;
}

.warningIconTooltip {
  margin: 0;
}
