@import '../../../../../../../../theme/main';

.prospection-body-container {
  width: 100%;
  background-color: #fff;
  padding: 16px;
  margin: 15px 0;
  border-left: 8px solid $color-primary;
  border-radius: 5px;
  box-shadow: 0 4px 24px 0 rgb(37 38 94 / 10%);

  .container-header {
    display: flex;
    justify-content: center;
    align-items: center;

    .container-title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
      flex-grow: 1;
      cursor: pointer;
    }

    .icon {
      color: $color-primary;
      width: 30px;
      height: 30px;
      cursor: pointer;

      &.down {
        transform: rotate(180deg);
      }
    }
  }

  .body-content {
    .MuiSelect-selectMenu {
      line-height: 16px;
    }

    &.hidden {
      display: none;
    }

    .mailbox-content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 5px 0;

      .mailbox-qty {
        width: 250px;
        margin-right: 50px;
      }

      button {
        width: 200px;
        margin: 0;
      }
    }

    .sector-responsable-content {
      .responsable-select {
        > div {
          margin-top: 10px;
        }
      }
      width: 300px;
    }

    .submit-block {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button {
        width: 30%;
      }
    }
  }
}
