@import '../../../../theme/main';

.environmentContainer {
  &.data {
    @include environment-container;

    .envSection {
      h3 {
        font-size: 0.9rem;
        margin: 10px 0 0 0;
      }

      .environmentRow {
        margin-left: 0;
      }
    }
  }

  .noCity {
    text-align: center;
    margin-top: 60px;
    font-size: 1.1em;
    line-height: 20px;
  }
}

.error {
  text-align: center;
  font-size: 1.2em;
  color: $color-error;
}
